const animates = document.querySelectorAll(".animate");
if(animates){
    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                entry.target.classList.toggle("in-view", entry.isIntersecting);
                if(entry.isIntersecting) observer.unobserve(entry.target);            
            });
        },
        {
            threshold: 0.1
        }
    );
    animates.forEach(animate => {
        observer.observe(animate);
    });
}

const scrollAnchor = function(id){
    var link_id = "#"+id;
    if(jQuery(link_id).length > 0){
        window.scrollTo(0, jQuery(link_id).offset().top-30);    
    }
}    

jQuery(function($){
    $('#offcanvasMenuOpen').on('click', function(e){
        e.preventDefault();
        $('#offcanvasMenu').toggleClass('show');
    })
    $('#offcanvasMenuClose').on('click', function(e){
        e.preventDefault();
        $('#offcanvasMenu').toggleClass('show');
    })
    $('#offcanvasMenu a').on('click', function(e){
        $('#offcanvasMenu').toggleClass('show');
    })
    $(window).scroll( function(){
        if ($(this).scrollTop()>100){
            $('#bar-scroll').slideDown();
        }else{
            $('#bar-scroll').slideUp();
        }
    })

    $('.slide-services').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
        
    })

    $('.slide-depoimentos').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000        
    })

    $('#form-cadastro').on('submit', function(e){
        e.preventDefault();
        var formData = $(this).serialize();
        $.post( "sendform.php", formData ).done(function(response){
            alert(response);
            $('#form-cadastro').get(0).reset();
        });
    })
})